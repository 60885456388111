import { render, staticRenderFns } from "./AllTranscheck.vue?vue&type=template&id=0835d651&scoped=true&"
import script from "./AllTranscheck.vue?vue&type=script&lang=js&"
export * from "./AllTranscheck.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0835d651",
  null
  
)

export default component.exports